<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
  <div>Confirmacion</div>
</div>

<mat-dialog-content>
  <p>¿Seguro desea eliminar registro?</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="close('No')">No</button>
  <button mat-button color="primary" (click)="close('Si')">Sí</button>
</mat-dialog-actions>
