<ngx-spinner type="ball-scale-multiple"> </ngx-spinner>

<div
  class="page background-pattern"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <div [@fadeInUp] class="card border-radius elevation">
    <div
      class="header padding background-primary"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <div class="logo">
        <figure>
          <img
            alt="Icono"
            class="img"
            src="assets/img/icon.png"
            loading="lazy"
          />
        </figure>
        <svg viewBox="0 0 33.74 33.99" xmlns="http://www.w3.org/2000/svg">
          <title>icon</title>
        </svg>
      </div>
    </div>
    <div class="padding" fxLayout="column" [formGroup]="formGroup">
      <div fxFlex="auto" fxLayout="column">
        <mat-form-field fxFlex="grow">
          <mat-label>Usuario</mat-label>
          <input
            placeholder="Ingresar Usuario"
            (keypress)="textOnly($event)"
            onDrag="return false"
            onDrop="return false"
            autocomplete="off"
            matInput
            required
            formControlName="user"
          />
          <mat-error *ngIf="formGroup.get('user').hasError('required')"
            >Indica el usuario
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="grow">
          <mat-label>Contraseña</mat-label>
          <input
            placeholder="Ingresar Contraseña"
            (keydown.enter)="onSubmit()"
            onDrag="return false"
            onDrop="return false"
            autocomplete="off"
            matInput
            [type]="inputType"
            formControlName="password"
            required
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="toggleVisibility()"
            matTooltip="Cambiar la visibilidad"
          >
            <mat-icon *ngIf="visible">visibility</mat-icon>
            <mat-icon *ngIf="!visible">visibility_off</mat-icon>
          </button>
          <mat-hint
            >Presiona para cambiar la visibilidad de la contraseña</mat-hint
          >
          <mat-error *ngIf="formGroup.get('password').hasError('required')"
            >Indica una contraseña
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <mat-checkbox
          (click)="allowRememberMe($event)"
          [checked]="checked"
          (change)="rememberMe($event)"
          class="caption"
          >Recuerdame</mat-checkbox
        >
      </div>

      <button class="btn" type="button" mat-raised-button (click)="onSubmit()">
        INGRESA
      </button>
    </div>
  </div>
</div>
