import { AppModule } from './app/app.module';
import * as Sentry from '@sentry/angular-ivy';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { CustomFunction } from './app/helper/custom-function';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (environment.production) {
  Sentry.init({
    dsn: `${environment.sentry}`,
    release: 'exphadis-intranet@1.0.0',
    environment: 'production',
    tracesSampleRate: 1.0, // Set tracesSampleRate to 0.2 to capture 20%
    sendDefaultPii: true,
    attachStacktrace: true,
    autoSessionTracking: true,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/service\.colegioexphadis\.com\.pe\/api/,
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    replaysSessionSampleRate: 0.1, // Capture Replay for 10% of all sessions,
    replaysOnErrorSampleRate: 1.0, // plus for 100% of sessions with an error
  });

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) =>
    CustomFunction.addToConsole('Error in bootstrap:', err, true),
  );
