import { Enums } from 'src/app/helper/enums';
import { CustomFunction } from 'src/app/helper/custom-function';
import { Notification } from 'src/app/model/master-data/announcement';
import { LIST_FADE_ANIMATION } from 'src/@fury/shared/list.animation';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { NotificationService } from 'src/app/service/notification.service';
import { AuthenticationService } from 'src/app/service/authentication.service';

@Component({
  selector: 'fury-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  animations: [...LIST_FADE_ANIMATION],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarNotificationsComponent implements OnInit {
  notifications: Notification[] = [];
  notificationsLength: number = 0;
  isOpen: boolean;
  personId: number = this.authenticationService.getUserInformation().personId;

  constructor(
    private cd: ChangeDetectorRef,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.getNotifications(CustomFunction.getCurrentTimeStamp());
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  // #region EVENT BUTTONS

  public markAsRead(notification: Notification) {
    const information = new Notification();
    information.id = notification.id;
    information.isReaded = true;
    information.personReaderId = this.personId;
    information.dateReaded = CustomFunction.getCurrentTimeStamp();

    this.notificationService.addNotificationReaded(information).subscribe({
      next: () => {
        notification.read = true;
        this.notificationsLength--;
        this.cd.detectChanges();
      },
    });
  }

  public dismiss(notification: Notification, event) {
    event.stopPropagation();

    const information = new Notification();
    information.id = notification.id;
    information.isReaded = true;
    information.personReaderId = this.personId;
    information.dateReaded = CustomFunction.getCurrentTimeStamp();

    this.notificationService.addNotificationReaded(information).subscribe({
      next: () => {
        notification.read = true;
        this.notificationsLength--;
        this.cd.detectChanges();
      },
    });
  }

  public markAllAsRead() {
    this.notifications.forEach((notification: Notification) => {
      const information = new Notification();
      information.id = notification.id;
      information.isReaded = true;
      information.personReaderId = this.personId;
      information.dateReaded = CustomFunction.getCurrentTimeStamp();

      this.notificationService.addNotificationReaded(information).subscribe({
        next: () => {
          notification.read = true;
          this.notificationsLength--;
          this.cd.detectChanges();
        },
      });
    });
  }

  // #region

  // #region PRIVATE

  private getNotifications(date: Date) {
    this.notificationService.getNotificationsV2(date, this.personId).subscribe({
      next: (data: Notification[]) => {
        data.forEach((x) => {
          x.icon = this.getPriorityIcon(x.priority);
          x.time = CustomFunction.getTimeAgo(new Date(x.dateToPublish));
          x.colorClass = this.getPriorityColorClass(x.priority);
          this.notifications.push(x);
        });

        this.notifications = data;
        this.notificationsLength = data.length;
        this.cd.detectChanges();
      },
    });
  }

  private getPriorityIcon(priority: Enums.NotificationPriority): string {
    switch (priority) {
      case Enums.NotificationPriority.High:
        return 'priority_high';
      case Enums.NotificationPriority.Medium:
        return 'notifications';
      case Enums.NotificationPriority.Low:
        return 'info';
      default:
        return 'notifications';
    }
  }

  private getPriorityColorClass(priority: Enums.NotificationPriority): string {
    switch (priority) {
      case Enums.NotificationPriority.High:
        return 'warn';
      case Enums.NotificationPriority.Medium:
        return 'primary';
      case Enums.NotificationPriority.Low:
        return '';
      default:
        return '';
    }
  }

  // #region
}
