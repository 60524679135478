import { NgModule } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { ForgotComponent } from './forgot/forgot.component';
import { FurySharedModule } from 'src/@fury/fury-shared.module';
import { InformationComponent } from './information/information.component';
import { MaterialModule } from 'src/@fury/shared/material-components.module';
import { AuthenticationRoutingModule } from './authentication-routing.module';

@NgModule({
  declarations: [
    InformationComponent,
    LogoutComponent,
    LoginComponent,
    InformationComponent,
    ForgotComponent,
  ],
  entryComponents: [
    InformationComponent,
    LogoutComponent,
    LoginComponent,
    InformationComponent,
    ForgotComponent,
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    MaterialModule,
    FurySharedModule,
    NgxSpinnerModule,
  ],
})
export class AuthenticationModule {}
