import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthenticationService } from '../../service/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService) {}

  async canActivate() {
    const tokenIsValid = this.authenticationService.tokenIsValid();

    if (tokenIsValid) {
      return tokenIsValid;
    }

    const token = this.authenticationService.getToken();

    await this.authenticationService.logout(token).toPromise();

    return false;
  }
}
