export const Message = {
  UnknownError:
    'Ocurrio un error en el sistema, comuniquese con el área de soporte',
  DebeIngresarUsuarioPasswordParaRecordar:
    'Debe Ingresar un usuario y contraseña para poder recordarlos',
  ItsNecessaryUsernameAndPassword:
    'Es necesario ingresar la información para iniciar sesión',
  NotConnectionWithServer:
    'No se pudo establecer comunicación con el Servidor.',
  HttpForbiddenError: 'El endopint que se quiere consumir esta prohibido',

  CreatedSuccessfully: 'Se registró el elemento satisfactoriamente',
  UpdatedSuccessfully: 'Se actualizó el registro satisfactoriamente',
  DeletedSuccessfully: 'Se eliminó el registro satisfactoriamente',

  RequestCantProcess: 'Su solicitud no pude procesarse.',

  NotEnoughtPermissions:
    'Usuario no tiene PERMISOS para la ejecución de dicho Método.',
  SessionExpired: 'Su sesión ha expirado, vuelva a ingresar al sistema',
  UserRegisterCorrectly: 'Se registro el alumno correctamente',
  DocumentCanBeUsed: 'El documento se puede usar sin problemas',
  DocumentAlreadyInUse: 'El documento ya esta haciendo usado',
  StudentEliminatedCorrectly: 'El estudiante se ha eliminado correctamente',
  PersonDeletedCorrectly: 'La persona se a eliminado correctamente',
  FunctionalityNotImplementedYet: 'La funcionalidad aún no esta implementada',
  EnrollmentRegisterSuccessfully: 'Se registro correctamente la matrícula',
  EnrollmentUpdatedSuccessfully: 'Se actualizo correctamente la matrícula',
  StudentUpdatedSuccessfully:
    'Se actualizo correctamente la información del estudiante',
  PyamentPlanCreatedCorrectly: 'Se crea el maestro de pago correctamente',
  MasterPaymentUpdatedSuccessfully:
    'Se actualizo correctamente el maestro de pagos',
  MasterPaymentEliminatedCorrectly:
    'El maestro de pagos se ha eliminado correctamente',
  MasterPromotionEliminatedCorrectly:
    'El maestro de promociones se ha eliminado correctamente',
  MasterPromotionUpdatedSuccessfully:
    'Se actualizo correctamente el maestro de promociones',
  PromotionCreatedCorrectly: 'Se crea el maestro de promociones correctamente',
  TrabajadorCreadoCorrectamente: 'Se creo el trabajador correctamente',
  TrabajadorEliminadoCorrectamente:
    'El trabajador se ha eliminado correctamente',
  TrabajadorActualizadoCorrectamente:
    'El trabajador se ha actualizado correctamente',
  UsuarioActualizadoCorrectamente: 'El usuario se ha actualizado correctamente',
  UsuarioRegistradoCorrectamente: 'El usuario se ha registrado correctamente',
  PlanPagosTrabajadorRegistradoCorrectamente:
    'Se crea el plan de pagos para el trabajador correctamente',
  DescuentoRegistradoCorrectamente: 'Se registro el descuento correctamente',
  AdelantoRegistradoCorrectamente:
    'Se registro el adelanto de sueldo correctamente',
  PagoTrabajadorRegistradoCorrectamente:
    'Se registro el pago del sueldo del trabajador correctamente',
  PromocionEstudianteRegistraCorrectamente:
    'Se registro la promoción del estudiante correctamente',
  PromocionEstudianteActualizadaCorrectamemte:
    'La promoción del estudiante se actualizo correctamente',
  PromocionEstudianteEliminadaCorrectamente:
    'La promoción del estudiante se ha eliminado correctamente',
  PagoRegistradoCorrectamente: 'Se registro el pago correctamente',
  PagoEstudianteActualizadoCorrectamente:
    'Se actualizo el pago del alumno correctamente',
  PagoEstudianteEliminadoCorrectamente:
    'El pago del estudiante se ha eliminado correctamente',
  PagoTrabajadorActualizadoCorrectamente:
    'Se actualizo el pago del trabajador correctamente',
  PagoTrabajadorEliminadoCorrectamente:
    'El pago del trabajador se ha eliminado correctamente',
  DescuentoTrabajadorActualizadoCorrectamente:
    'Se actualizo el descuento del trabajador correctamente',
  DescuentoTrabajadorEliminadoCorrectamente:
    'El descuento del trabajador se ha eliminado correctamente',
  PlanPagoTrabajadorActualizadoCorrectamente:
    'Se actualizo el plan de pago del trabajador correctamente',
  ArchivoCargadoCorrectamente: 'Se cargo el archivo correctamente',
  CursoRegistradoCorrectamente: 'Se registro el curso satisfactoriamente',
  CursoEliminadoCorrectamente: 'Se elimino el curso satisfactoriamente',
  CursoActualizadoCorrectamente: 'Se actualizo el curso satisfactoriamente',
  AsignaturaRegistradaCorrectamente:
    'Se registro la asignatura satisfactoriamente',
  TutoriaRegistradaCorrectamente: 'Se registro la tutoria satisfactoriamente',
  TutoriaActualizadaCorrectamente: 'Se actualizo la tutoria satisfactoriamente',
  AsignaturaActualizadaCorrectamente:
    'Se actualizo la asignatura satisfactoriamente',
  ObservacionRegistradaCorrectamente:
    'Se registro la observación satisfactoriamente',
  ObservacionEliminadaCorrectamente:
    'Se elimino la observación satisfactoriamente',
  ObservacionActualizadaCorrectamente:
    'Se actualizo la observación satisfactoriamente',
  JustificacionRegistradaCorrectamente:
    'Se registro la justificación de la falta satisfactoriamente',
  IngresarTextoABuscar: 'Debe ingresar un texto para realizar la búsqueda',
  CriterioActualizadoCorrectamente:
    'Se actualizo el criterio satisfactoriamente',
  CriterioCreadoCorrectamente: 'Se registro el criterio satisfactoriamente',
  CriterioEliminadoCorrectamente: 'Se elimino el criterio satisfactoriamente',
  CompetenciaCreadoCorrectamente:
    'Se registro la competencia satisfactoriamente',
  CompetenciaActualizadoCorrectamente:
    'Se actualizo la competencia satisfactoriamente',
  CompetenciaEliminadoCorrectamente:
    'Se elimino la competencia satisfactoriamente',
  IngresarNivelParaBuscar: 'Debe ingresar un nivel para buscar los cursos',
  MustFillAllTheInformation:
    'Debe ingresar la información necesaria para registrar',
  IngresarInformacionParaRegistrarPagoBancario:
    'Debe ingresar la información del pago bancario para registrar',
  IngresarInformacionParaActualizarPagoBancario:
    'Debe ingresar la información del pago bancario para actualizar',
  ValidarDocumentoApoderadoAlumno:
    'Uno de los datos ingresados en el documento para el apoderado o el alumno no es correcto, por favor revíselos',
  ValidarFormatoDocumento: 'El formato de documento ingresado no es correcto',
  SeleccionarTipoDocumento:
    'Es necesario seleccionar el tipo de documento para poder realizar la validación',
  ValidarDocumento:
    'El datos ingresado en el documento no es correcto, por favor revíselo',
  MustFulfillNecessaryInformationForTheUpdate:
    'Debe ingresar la información necesaria para actualizar',
  IngresarEquivalenciaParaBuscar:
    'Debe ingresar la equivalencia para realizar la búsqueda',
  EquivalenciaEliminadoCorrectamente:
    'Se elimino la equivalencia satisfactoriamente',
  PendienteValidacionPagoRegistradoCorrectamente:
    'Se registro la pendiente de validación de pago correctamente',
  PendienteValidacionPagoActualizadaCorrectamente:
    'Se actualizo la pendiente de validación de pago correctamente',
  SoloActualizarEstadoPendiente:
    'Sólo se puede actualizar las pendientes de validación que tienen estado pendiente',
  PendienteValidacionPagoEliminadaCorrectamente:
    'Se elimino la pendiente de validación de pago correctamente',
  NecesarioIngresarNumeroOperacion:
    'Es necesario indicar un número de operación para realizar la búsqueda',
  PagoActualizadoCorrectamente: 'Se actualizo el pago correctamente',
  EsNecesarioRegistrarPagoPendiente:
    'Es necesario registrar un pago asociado a la pendiente que se acaba de aprobar',
  PendienteValidacionActualizadaCorrectamente:
    'Se actualizo la pendiente de validación correctamente',
  NoSePuedeActualizarPendienteValidacion:
    'Sólo se pude actualizar las pendientes de validación que poseen el estado de pendiente',
  NoSePuedeEliminarPendienteValidacion:
    'Sólo se pude eliminar las pendientes de validación que poseen el estado de pendiente',
  ConfiguracionUnidadesActualizadaCorrectamente:
    'Se actualizaron las unidades correctamente',
  ConfiguracionUnidadesRegistradaCorrectamente:
    'Se registraron las unidades correctamente',
  SubjectDeletedSuccessfully: 'Se elimino la asignatura satisfactoriamente',
  MatriculaEliminadaCorrectamente: 'Se elimino la matrícula satisfactoriamente',
  FillInformationForSearching:
    'Es necesario ingresar toda la información obligatoria para realizar la búsqueda',
  IngressValidYearForSearchingArea:
    'Es necesario que ingrese un año válido para buscar áreas',
  FuncionalidadAunNoImplementada:
    'No se ha implementado esta funcionalidad aún',
  TutoriaEliminadaCorrectamente: 'Se elimino la tutoria satisfactoriamente',
  RecomendacionRegistradaCorrectamente:
    'Se registro la recomendación satisfactoriamente',
  RecomendacionActualizadaCorrectamente:
    'Se actualizo la recomendación satisfactoriamente',
  RecomendacionEliminadaCorrectamente:
    'Se eliminó la recomendación satisfactoriamente',
  MustSelectCompetencies:
    'Debe seleccionar las competencias que desee vincular a la asignatura en esta unidad',
  SolicitudInformesRegistradaCorrectamente:
    'Se registro la solicitud de informes satisfactoriamente',
  SolicitudInformesActualizadaCorrectamente:
    'Se actualizo la solicitud de informes satisfactoriamente',
  SolicitudInformesEliminadaCorrectamente:
    'Se elimino la solicitud de informes satisfactoriamente',
  CompetenceSelectedCorrectly:
    'Se seleccionarón las competencias para la unidad correctamente',
  DindtUploadThePaymentPicture:
    'No se ha cargado la imagen del voucher pago, es necesario cargarla para poder realizar el registro',
  MasterPaymentNotEditable:
    'Este pago, al poseer pagos vinculados. No puede ser editado directamente',
  // tslint:disable-next-line: max-line-length
  IndicationForCompletePaymentIsNotFullfillWithRequeriment:
    // tslint:disable-next-line: no-invalid-template-strings
    'Se está indicando que el pago es por el monto completo, pero el monto ingresado ${amountFilled} no es suficiente para completarlo',
  WorkerDosentHaveUserRegistered:
    'El trabajador aún no tiene un usuario registrado, debe generarle un contrato para que se genere un usuario',
  EnterInformationToConsumeReport:
    'Debe ingresar la infomación necesaria para poder obtener el reporte',
};
