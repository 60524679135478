<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
  <div>Cerrar Sesión</div>
</div>

<mat-dialog-content>
  <p>¿Seguro desea salir del sistema?</p>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-button (click)="logout('No')">No</button>
  <button mat-button color="primary" (click)="logout('Si')">Yes</button>
</mat-dialog-actions>
