import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'exphadis-modal-validate',
  templateUrl: './modal-validate.component.html',
})
export class ModalValidateComponent implements OnInit {
  dialogRef$: MatDialogRef<ModalValidateComponent>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<ModalValidateComponent>,
  ) {
    this.dialogRef$ = dialogRef;
  }

  ngOnInit(): void {}

  close(answer: string) {
    this.dialogRef$.close(answer);
  }
}
