import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Login } from 'src/app/model/authentication/login';
import { CustomFunction } from 'src/app/helper/custom-function';
import { AuthenticationService } from 'src/app/service/authentication.service';

@Component({
  selector: 'fury-information',
  templateUrl: './information.component.html',
})
export class InformationComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
  ) {}

  public ngOnInit(): void {
    this.initialValidationStudentForm();
    this.getSessionInformation();
  }

  // #region PRIVATE

  private initialValidationStudentForm() {
    this.form = this.fb.group({
      completeName: null,
      age: null,
      document: null,
      email: null,
      mobile: null,
      roles: null,
    });
  }

  private getSessionInformation() {
    const user: Login = this.authenticationService.getUserInformation();
    const roles: string = CustomFunction.getSplitStringFromList(
      user.access.roles,
      ', ',
    );

    if (user) {
      this.form.controls.completeName.setValue(user.completeName);
      this.form.controls.age.setValue(user.age);
      this.form.controls.document.setValue(user.document);
      this.form.controls.email.setValue(user.email);
      this.form.controls.mobile.setValue(user.mobile);
      this.form.controls.roles.setValue(roles);
    }
  }

  // #region
}
