import { Audit } from '../base/audit';
import { Enums } from 'src/app/helper/enums';
import { Person } from '../person/person';

export class Announcement extends Audit {
  id: number;
  title: string;
  body: string;
  roleIds: number[] = [];
  roles: string;
  filesNumber: number;
  dateToPublish: Date;
  mediaId: number;
  levelId: number;
  gradeIds: number[] = [];
}

export class Notification extends Audit {
  id: number;
  title: string;
  message: string;
  personPublisherId: number;
  personPublisher: Person;
  priority: Enums.NotificationPriority;
  roleIds: number[] = [];
  roles: string;
  levelId: number;
  gradeIds: number[] = [];
  dateToPublish: Date;
  messageType: Enums.NotificationMessageType;
  priorityName: string;
  messageTypeName: string;
  mediaId?: number;
  isReaded: boolean = false;
  personReaderId: number;
  dateReaded: Date;
  icon: string;
  read: boolean = false;
  time: string;
  colorClass: string;
}
