<div class="sidenav" fxLayout="column">
  <div class="sidenav-toolbar" fxLayout="row" fxLayoutAlign="start center">
    <div [routerLink]="['/dashboard']" class="sidenav-toolbar-icon">
      <div *ngIf="!collapsed">
        <img
          style="height: 60px; margin-left: 50px; margin-bottom: -5px"
          src="assets/img/icon.png"
          fxFlexAlign="right"
          class="logo"
        />
      </div>
    </div>

    <span fxFlex><!-- fill space --></span>

    <div fxFlexAlign="center" fxHide fxShow.gt-md>
      <button
        (click)="toggleCollapsed()"
        class="sidenav-toolbar-collapse-toggle"
        mat-icon-button
      >
        <mat-icon *ngIf="!collapsed" class="sidenav-toolbar-collapse-icon"
          >radio_button_checked</mat-icon
        >
        <mat-icon *ngIf="collapsed" class="sidenav-toolbar-collapse-icon"
          >radio_button_unchecked</mat-icon
        >
      </button>
    </div>
  </div>

  <fury-scrollbar class="sidenav-items" fxFlex="auto">
    <div *ngIf="sidenavUserVisible$ | async" class="sidenav-user">
      <img class="sidenav-user-image" src="{{ imagePath }}" />
      <h4 class="sidenav-user-name">{{ userInformation.completeName }}</h4>
      <h5 class="sidenav-user-email">{{ userInformation.email }}</h5>

      <div class="sidenav-user-icons" fxLayout="row">
        <a (click)="information()" mat-icon-button>
          <mat-icon>account_circle</mat-icon>
        </a>
        <a (click)="changePassword()" mat-icon-button>
          <mat-icon>local_police</mat-icon>
        </a>
        <a (click)="logout()" mat-icon-button>
          <mat-icon>exit_to_app</mat-icon>
        </a>
      </div>
    </div>

    <fury-sidenav-item
      *ngFor="let item of items$ | async"
      [item]="item"
      [level]="0"
    ></fury-sidenav-item>
  </fury-scrollbar>
</div>
