import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constant } from 'src/app/helper/constant';
import { SidenavService } from './sidenav.service';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/model/authentication/user';
import { Login } from 'src/app/model/authentication/login';
import { ThemeService } from '../../../@fury/services/theme.service';
import { SidenavItem } from '../../model/layout/sidenav-item.interface';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { UpdateLoginComponent } from 'src/app/pages/user/update/update.component';
import { LogoutComponent } from 'src/app/pages/authentication/logout/logout.component';
import {
  Component,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { InformationComponent } from 'src/app/pages/authentication/information/information.component';

@Component({
  selector: 'fury-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
  imagePath: string;
  userInformation: Login;
  sidenavUserVisible$ = this.themeService.config$.pipe(
    map((config) => config.sidenavUserVisible),
  );

  @Input()
  @HostBinding('class.collapsed')
  collapsed: boolean;

  @Input()
  @HostBinding('class.expanded')
  expanded: boolean;

  items$: Observable<SidenavItem[]>;

  constructor(
    private dialog: MatDialog,
    private themeService: ThemeService,
    private sidenavService: SidenavService,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.userInformation = this.authenticationService.getUserInformation();

    this.validateImage();

    this.items$ = this.sidenavService.items$.pipe(
      map((items: SidenavItem[]) =>
        this.sidenavService.sortRecursive(items, 'position'),
      ),
    );
  }

  toggleCollapsed() {
    this.sidenavService.toggleCollapsed();
  }

  @HostListener('mouseenter')
  @HostListener('touchenter')
  onMouseEnter() {
    this.sidenavService.setExpanded(true);
  }

  @HostListener('mouseleave')
  @HostListener('touchleave')
  onMouseLeave() {
    this.sidenavService.setExpanded(false);
  }

  ngOnDestroy() {}

  validateImage() {
    this.imagePath = !this.userInformation.sex
      ? Constant.GenericImageWoman
      : Constant.GenericImageMan;
  }

  changePassword() {
    const user = new User();
    user.id = this.userInformation.userId;
    user.idPerson = this.userInformation.personId;
    user.username = this.userInformation.username;
    user.active = this.userInformation.active;
    user.userRegister = this.userInformation.userRegister;
    user.timeRegister = this.userInformation.timeRegister;

    const data = {
      completeName: this.userInformation.completeName,
      user,
      changeUsername: false,
      changeActive: false,
      shouldCreate: false,
    };

    this.dialog.open(UpdateLoginComponent, {
      data,
    });
  }

  information() {
    this.dialog.open(InformationComponent);
  }

  logout() {
    this.dialog
      .open(LogoutComponent, {})
      .afterClosed()
      .subscribe((answer) => {
        if (answer === Constant.UserResponseAfirmative) {
          const tokenIsValid = this.authenticationService.tokenIsValid();

          if (tokenIsValid) {
            const token = this.authenticationService.getToken();

            this.logoutService(token).then();
          } else {
            return;
          }
        }
      });
  }

  private async logoutService(token: string) {
    await this.authenticationService.logout(token).toPromise();
  }
}
