import { Injectable } from '@angular/core';
import { Enums } from 'src/app/helper/enums';
import {
  Announcement,
  Notification,
} from 'src/app/model/master-data/announcement';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { CustomFunction } from 'src/app/helper/custom-function';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(protected httpClient: HttpClient) {}

  getAnnouncements(): Observable<Announcement[]> {
    return this.httpClient.get<Announcement[]>(
      `${environment.backend}/${Enums.ApiPath.NOTIFICATION}/announcement`,
    );
  }

  addAnnouncement(information: any): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.backend}/${Enums.ApiPath.NOTIFICATION}/announcement`,
      CustomFunction.serialize(information),
    );
  }

  updateAnnouncement(information: any): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.backend}/${Enums.ApiPath.NOTIFICATION}/announcement`,
      CustomFunction.serialize(information),
    );
  }

  deleteAnnouncement(announcementId: number): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.backend}/${Enums.ApiPath.NOTIFICATION}/announcement/${announcementId}`,
    );
  }

  deleteMediaForAnnouncement(mediaId: number): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.backend}/${Enums.ApiPath.NOTIFICATION}/media/${mediaId}/announcement`,
    );
  }

  getNotifications(): Observable<Notification[]> {
    return this.httpClient.get<Notification[]>(
      `${environment.backend}/${Enums.ApiPath.NOTIFICATION}`,
    );
  }

  getNotificationsV2(date: Date, personId: number): Observable<Notification[]> {
    return this.httpClient.get<Notification[]>(
      `${environment.backend}/${Enums.ApiPath.NOTIFICATION}/${date}/person/${personId}`,
    );
  }

  addNotification(information: any): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.backend}/${Enums.ApiPath.NOTIFICATION}`,
      CustomFunction.serialize(information),
    );
  }

  updateNotification(information: any): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.backend}/${Enums.ApiPath.NOTIFICATION}`,
      CustomFunction.serialize(information),
    );
  }

  deleteNotification(notificationId: number): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.backend}/${Enums.ApiPath.NOTIFICATION}/${notificationId}`,
    );
  }

  addNotificationReaded(information: any): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.backend}/${Enums.ApiPath.NOTIFICATION}/readed`,
      CustomFunction.serialize(information),
    );
  }
}
