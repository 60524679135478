import { NgModule } from '@angular/core';
import { Enums } from 'src/app/helper/enums';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotComponent } from './forgot/forgot.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: Enums.SitePath.FORGOT_PASSWORD,
    component: ForgotComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
