import { Injectable } from '@angular/core';
import { Enums } from 'src/app/helper/enums';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { CustomFunction } from 'src/app/helper/custom-function';
import { HealthCheck } from '../model/master-data/health-check';
import { GenericOption } from 'src/app/model/base/generic-option';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  constructor(protected httpClient: HttpClient) {}

  getConfigurationVariables(): Observable<GenericOption[]> {
    return this.httpClient.get<GenericOption[]>(
      `${environment.backend}/${Enums.ApiPath.MAINTENANCE}/variables`,
    );
  }

  getHealthCheck(): Observable<HealthCheck> {
    return this.httpClient.get<HealthCheck>(
      `${environment.backend}/${Enums.ApiPath.HEALTH}`,
    );
  }

  getHardReloadingCount(information: any): Observable<number> {
    return this.httpClient.put<number>(
      `${environment.backend}/${Enums.ApiPath.MAINTENANCE}/hard-reloading`,
      CustomFunction.serialize(information),
    );
  }

  addHardReloading = (information: any): Observable<void> =>
    this.httpClient.post<void>(
      `${environment.backend}/${Enums.ApiPath.MAINTENANCE}/hard-reloading`,
      CustomFunction.serialize(information),
    );
}
